import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Images from '../components/Images'
import Gallery from '../components/Gallery'
import Menu from '../components/Menu'
import Section from '../components/Section'
import SEO from '../components/seo'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ProjectPage = ({ data, pageContext }) => {
  const [scrollY, setScrollY] = useState(0)
  const [gallery, setGallery] = useState({
    isOpen: false,
    index: 0,
  });
  const pageEntries = data.contentfulMenu.entries.filter(entry => entry.slug === pageContext.slug);
  const aboutLeft = pageEntries[0] !== undefined ? pageEntries[0].childContentfulProjectAboutLeftColumnRichTextNode : null;
  const aboutRight = pageEntries[0] !== undefined ? pageEntries[0].childContentfulProjectAboutRightColumnRichTextNode : null;
  const linkUrl = pageEntries[0] !== undefined ? pageEntries[0].linkUrl : null;
  const linkText = pageEntries[0] !== undefined ? pageEntries[0].linkText : null;

  const handleSetGallery = (op, index) => {
    if (op === true) setScrollY(window.scrollY)

    setGallery({
      isOpen: op,
      index: index,
    })
  }

  useEffect(() => {
    // Update the document title using the browser API
    if (scrollY > 0) {
      window.scroll(0, scrollY)
      console.log('great than!')
    }
  })

  return (
    <Layout 
    className="project"
    projectPage={true}
    style={gallery.isOpen ? {position: 'fixed'} : null}
    >
      <SEO title='Index' />
      <div>
        <Menu 
          data={data.contentfulMenu.entries}
          projectPage={true}
        />
      </div>
      <div id='mainContent'>
        <Section className='projectSection'>
            <article>
              <h3>About</h3>
              <div>{aboutLeft !== null ? documentToReactComponents(aboutLeft.json) : null}</div>
              <div>
                {aboutRight !== null ? documentToReactComponents(aboutRight.json) : null}
                {linkUrl
                  ? <p><a href={linkUrl} rel="noopenner noreferrer" target='blank'>
                      {linkText ? linkText : linkUrl}
                    <svg 
                      version="1.1" 
                      xmlns="http://www.w3.org/2000/svg" 
                      x="0px" 
                      y="0px" 
                      width="10px"
                      height="10px" viewBox="0 0 10 10" enableBackground="new 0 0 10 10"
                    >
                      <g id="Layer_1" display="none">
                        <path display="inline" fill="none" stroke="#000000" d="M9,1L1,9 M9,1H3 M9,1v6" />
                      </g>
                      <g id="Layer_2">
                        <path fill="none" stroke="#000000" d="M3,1h6v6 M1,9l8-8" />
                      </g>
                    </svg>
                    </a></p>
                  : null
                }
              </div>
            </article>
        </Section>
        <Images 
          data={pageContext.assets} 
          handleSetGallery={handleSetGallery}
        />
        {gallery.isOpen
          ? <Gallery
              data={pageContext.assets}
              gallery={gallery}
              handleSetGallery={handleSetGallery}
              title={pageEntries[0].title}
            />
          : null
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery {
    contentfulMenu {
      entries {
        id
        title
        slug
        year
        linkUrl
        linkText
        childContentfulProjectAboutLeftColumnRichTextNode {
          id
          json
        }
        childContentfulProjectAboutRightColumnRichTextNode {
          id
          json
        }
        assets {
          id
          description
          fixed(width:300) {
            src
            srcWebp
          }         
          file {
              url
              fileName
              contentType
            }
        }
      }
    }
  }
`

export default ProjectPage