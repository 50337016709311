import React from 'react'
import LazyLoad from 'react-lazyload'
import './Images.scss'

const getMediaClassName = (description) => {
  if (description.includes('two-up') && description.includes('left')) {
    return 'twoUp--left'
  }

  if (description.includes('two-up') && description.includes('right')) {
    return 'twoUp--right'
  }
}

const Images = ({ data, handleSetGallery }) => (
  <div>
    <ul className='images'>
      {(data !== null) && (data.length > 0)
        ? data.map((item, index) => (
          <li 
            className={getMediaClassName(item.description)}
            key={index}
          >
            <button
              onClick={() => handleSetGallery(true, index)}
            >
              {item.file.contentType.includes('image')
                ? <picture>
                    <source srcSet={item.fluid.srcSet} />
                    <source srcSet={item.fluid.srcSetWebp} />
                    <img
                      alt={item.description}
                      loading='lazy'
                      src={item.fluid.src}
                    />
                  </picture> 
                : null
              }
              {item.file.contentType.includes('video')
                ? <LazyLoad
                    once={true}
                    offset={250}
                  >
                    <video 
                        autoPlay={item.description.includes('controls') ? false : true}
                        controls={item.description.includes('controls') ? true : false}
                        muted={item.description.includes('controls') ? true : false}
                        loop={item.description.includes('controls') ? false : true}
                        width='925'
                        playsInline={true}
                      >
                        <source src={item.file.url} type={item.file.contentType} />
                      </video>
                  </LazyLoad> 
                : null
              }
              <span>{item.file.contentType.includes('video') ? '+ Watch' : '+ Enlarge'}</span>
            </button>
            </li>
          ))
        : null
      }
    </ul>
  </div>
)

export default Images