import React, { useEffect, useState } from 'react'
import FocusTrap from 'react-focus-trap'
import { Swipeable } from 'react-swipeable'
import './Gallery.scss'

const Gallery = ({ data, gallery, handleSetGallery, title }) => {
  const [selectedImage, setSelectedImage] = useState(gallery.index);
  const [windowHeight, setWindowHeight] = useState('100vh')

  const handleSetWindowHeight = () => {
    setWindowHeight(window.innerHeight)
  }

  const handleSetSelectedImage = direction => {
    if (direction === 'back' && selectedImage > 0) {
      setSelectedImage(selectedImage - 1)
    } else if (direction === 'back' && selectedImage === 0) {
      setSelectedImage(data.length - 1)
    } else if (direction === 'forward' && selectedImage < data.length - 1) {
      setSelectedImage(selectedImage + 1)
    } else if (direction === 'forward' && selectedImage === data.length - 1) {
      setSelectedImage(0)
    }
  }
  const handleKeyPress = (e) => {
    let keyCode = e.code

    if (keyCode === 'Escape') handleSetGallery(false, 0)
    if (keyCode === 'ArrowRight') handleSetSelectedImage('forward')
    if (keyCode === 'ArrowLeft') handleSetSelectedImage('back')
  }

  useEffect(() => {
    handleSetWindowHeight()
    window.addEventListener('resize', handleSetWindowHeight)
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener("resize", handleSetWindowHeight);
      window.removeEventListener("keydown", handleKeyPress);
    }
  })

  return (
    <FocusTrap>
    <div className='gallery' style={{'height': windowHeight}}>

        <div className='galleryHeader'>
          <span>{`${title} (${selectedImage + 1}/${data.length})`}</span>
          <button
            className='headerClose'
            onClick={() => handleSetGallery(false, 0)}
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L1 9M9 9L1 1" stroke="black" />
            </svg>
            <span>Close</span>
          </button>
        </div>

        <div className='galleryContainer'>
          <button onClick={() => handleSetSelectedImage('back')}>
            <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1L2 13L14 25" stroke="black" strokeWidth="2" />
            </svg>
          </button>
          {/* <Swipeable 
            className='galleryImage'
            onSwipedLeft={() => handleSetSelectedImage('forward')} 
            onSwipedRight={() => handleSetSelectedImage('back')}  
          >  */}
            {data.map((item, index) => (
              <Swipeable
                className='galleryImage'
                onSwipedLeft={() => handleSetSelectedImage('forward')}
                onSwipedRight={() => handleSetSelectedImage('back')}
                key={index}
                style={{ display: `${selectedImage === index ? 'flex' : 'none'}`}}
              > 
                {item.file.contentType.includes('image')
                  ? <picture>
                      <source srcSet={item.fluid.srcSet} />
                      <source srcSet={item.fluid.srcSetWebp} />
                      <img
                        alt={item.description}
                        loading='lazy'
                        src={item.fluid.src}
                      />
                    </picture>
                  : null
                }
                {item.file.contentType.includes('video')
                  ? <video 
                      autoPlay={item.description.includes('controls') ? false : true} 
                      controls={item.description.includes('controls') ? true : false} 
                      muted 
                      loop
                      playsInline={true}
                      width='1500'
                    >
                    <source
                      src={item.file.url} 
                      type={item.file.contentType} 
                    />
                  </video>
                  : null
                }
              </Swipeable>
            ))}
          <button onClick={() => handleSetSelectedImage('forward')}>
            <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L13 13L1 25" stroke="black" strokeWidth="2" />
            </svg>
          </button>
        </div>

      <div 
        className='galleryProgressBar'
        style={{width: `${((selectedImage + 1) / (data.length)) * 100}%`}}
        ></div>
    </div>
        </FocusTrap>
  )
}

export default Gallery